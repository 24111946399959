import React from "react"

function Container(props) {
  const { maxWidth } = props

  return (
    <div className={`container mx-auto px-4 ${maxWidth ? `xl:max-w-7xl` : ``}`}>
      {props.children}
    </div>
  )
}

export default Container
