/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext } from "react"
import { useMediaQuery } from "react-responsive"
import ShippingOptions from "./ShippingOptions"
import CartItemCard from "../Cards/CartItemCard"
import Button from "../Elements/Button"
import CartContext from "../../context/Cart/CartContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"

const CartOrders = ({
  cart,
  item,
  index,
  parentShippingState,
  setOrderState,
  orderState,
  parentId,
  setParentId,
  setItemShippingState,
  setOrderNotes,
  setGiftEmail,
  setAssetQty,
  notifyEmail,
  length,
}) => {
  const { handleRemoveClick, quantityUpdate } = useContext(CartContext)
  const { userType } = useContext(AuthenticationContext)

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  })
  const { addresses, items } = item
  // const address =
  //   addresses?.address?.split(",")?.[addresses?.address?.split(",")?.length - 1]
  console.log(addresses)
  return (
    <div className="border border-[#EBEBEB] rounded-[8px] mb-4">
      <div className="flex items-center p-[15px] md:p-[30px]">
        <h1
          className={`${
            isMobile ? `text-semibold-16` : `text-semibold-18`
          } w-[60px]`}
        >
          #{index + 1}
        </h1>
        <div className="flex flex-col gap-3">
          <h1
            className={`${
              isMobile ? `text-semibold-16` : `text-semibold-18`
            } w-full`}
          >
            {addresses?.address_name?.toUpperCase()}
          </h1>
          {!isTablet ? (
            <div
              css={css`
                font-family: "Jost";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                display: inline-block;
                padding: 0;
                letter-spacing: 0.02em;
                color: #717171;
                display: flex;
                gap: 40px;
              `}
            >
              {addresses?.company_name !== null &&
                addresses?.company_name !== "" && (
                  <p>{addresses?.company_name}</p>
                )}
              {((addresses?.first_name !== null &&
                addresses?.first_name !== "") ||
                (addresses?.last_name !== null &&
                  addresses?.last_name !== "")) && (
                <span className="flex gap-1">
                  {addresses?.first_name && <p>{addresses?.first_name}</p>}
                  {addresses?.last_name && <p>{addresses?.last_name}</p>}
                </span>
              )}

              {addresses?.address !== null && addresses?.address !== "" && (
                <p>{addresses?.address}</p>
              )}
              {addresses?.phone !== null && addresses?.phone !== "" && (
                <p>{addresses?.phone}</p>
              )}
            </div>
          ) : (
            <>
              <p
                css={css`
                  font-family: "Jost";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 16px;
                  display: inline-block;
                  padding: 0;
                  letter-spacing: 0.02em;
                  color: #717171;
                `}
              >
                {addresses?.address !== null && addresses?.address !== "" && (
                  <p>{addresses?.address}</p>
                )}
              </p>
              <div
                css={css`
                  font-family: "Jost";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 16px;
                  display: inline-block;
                  padding: 0;
                  letter-spacing: 0.02em;
                  color: #717171;
                  display: flex;
                  justify-content: space-between;
                  gap: 20px;
                  flex-wrap: wrap;
                `}
              >
                {addresses?.company_name !== null &&
                  addresses?.company_name !== "" && (
                    <p>{addresses?.company_name}</p>
                  )}
                {((addresses?.first_name !== null &&
                  addresses?.first_name !== "") ||
                  (addresses?.last_name !== null &&
                    addresses?.last_name !== "")) && (
                  <span className="flex gap-1">
                    {addresses?.first_name && <p>{addresses?.first_name}</p>}
                    {addresses?.last_name && <p>{addresses?.last_name}</p>}
                  </span>
                )}
                {addresses?.phone !== null && addresses?.phone !== "" && (
                  <p>{addresses?.phone}</p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <hr class="h-px bg-[#EBEBEB] border-0 ml-8 mr-8 mb-6" />
      {!isTablet ? (
        <div className="flex mb-6">
          <div
            className={`w-full ${
              userType.brandEditor === true ? "lg:w-[60%]" : ""
            } lg:pr-[30px] border-r border-[#EBEBEB] px-8`}
          >
            {cart.length > 0 && (
              <>
                <ul>
                  {items.map((v, k) =>
                    v.addresses.map((c, kk) => {
                      console.log(v, "v")
                      console.log(c, "c")
                      return (
                        <CartItemCard
                          small
                          key={`${k}_${kk}`}
                          {...v}
                          {...c}
                          address={c}
                          mainIndex={k}
                          storeIndex={kk}
                          storeId={c.address_id}
                          assetId={v.asset_id}
                          setAssetQty={setAssetQty}
                          length={length}
                          assetLength={items.length}
                        />
                      )
                    })
                  )}
                </ul>
                <div className="flex items-center mt-6">
                  {
                    //   <span className="ml-auto text-med-14">
                    //   Direct to your Location
                    // </span>
                  }
                  <Button
                    sm
                    plain
                    blue
                    onClick={() => {
                      items.map(v =>
                        handleRemoveClick(v.asset_id, addresses.address_id)
                      )
                      //remove the item from setAssetQty array also using address_id
                      setAssetQty(prev =>
                        prev.filter(
                          item => item.address_id !== addresses.address_id
                        )
                      )
                    }}
                  >
                    Remove Order
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="w-full lg:w-[40%] px-8">
            <ShippingOptions
              parentId={parentId}
              setParentId={setParentId}
              name={`child_shipping_${index}`}
              parentShippingState={parentShippingState}
              setOrderState={setOrderState}
              orderState={orderState}
              parentIndex={index}
              setItemShippingState={setItemShippingState}
              setOrderNotes={setOrderNotes}
              idx={index}
              // specifyGiftNotificationEmail={specifyGiftNotificationEmail}
              // setSpecifyGiftNotificationEmail={setSpecifyGiftNotificationEmail}
              address_id={addresses.address_id}
              setGiftEmail={setGiftEmail}
              notifyEmail={notifyEmail}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col mb-6">
          <div
            className={`w-full ${
              userType.brandEditor === true ? "lg:w-[60%]" : ""
            } lg:pr-[30px] border-r border-[#EBEBEB] px-8`}
          >
            {cart.length > 0 && (
              <>
                <ul>
                  {items.map((v, k) =>
                    v.addresses.map((c, kk) => {
                      console.log(v, "v")
                      console.log(c, "c")
                      return (
                        <CartItemCard
                          small
                          key={`${k}_${kk}`}
                          {...v}
                          {...c}
                          address={c}
                          mainIndex={k}
                          storeIndex={kk}
                          storeId={c.address_id}
                          assetId={v.asset_id}
                          setAssetQty={setAssetQty}
                          length={length}
                          assetLength={items.length}
                        />
                      )
                    })
                  )}
                </ul>
                <div className="flex items-center mt-6 mb-6">
                  {
                    //   <span className="ml-auto text-med-14">
                    //   Direct to your Location
                    // </span>
                  }
                  <Button
                    sm
                    plain
                    blue
                    onClick={() =>
                      items.map(v =>
                        handleRemoveClick(v.asset_id, addresses.address_id)
                      )
                    }
                  >
                    Remove Order
                  </Button>
                </div>
              </>
            )}
          </div>
          {userType.brandEditor === true && (
            <div className="w-full lg:w-[40%] px-8">
              <ShippingOptions
                parentId={parentId}
                setParentId={setParentId}
                name={`child_shipping_${index}`}
                parentShippingState={parentShippingState}
                setOrderState={setOrderState}
                orderState={orderState}
                parentIndex={index}
                setItemShippingState={setItemShippingState}
                setOrderNotes={setOrderNotes}
                idx={index}
                // specifyGiftNotificationEmail={specifyGiftNotificationEmail}
                // setSpecifyGiftNotificationEmail={
                //   setSpecifyGiftNotificationEmail
                // }
                address_id={addresses.address_id}
                setGiftEmail={setGiftEmail}
                notifyEmail={notifyEmail}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CartOrders
