import { Link } from "gatsby"
import React from "react"
import "swiper/css/bundle"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import ProductGridCard from "./Cards/ProductGridCard"

function RelatedProduct({ title, subtitle, products }) {
  return (
    <div className="mt-10">
      <div className="mb-[55px]">
        <h3 className="text-block-subhead uppercase md:text-center">
          {subtitle}
        </h3>
        <h2 className="md:text-center text-strong-30">{title}</h2>
      </div>

      <Swiper
        pagination={{
          type: "progressbar",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        rewind={true}
        className="swiper-bg-clear !pt-0 !pb-8"
        slidesPerView={1}
        spaceBetween={15}
        breakpoints={{
          640: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        }}
      >
        {products?.length > 0 &&
          products.map((v, k) => {
            // console.log(v)
            return (
              <SwiperSlide key={k}>
                <ProductGridCard
                  displayBlock
                  {...v}
                  // linkUrl={

                  //     ? `/assets/${v.id}`
                  //     : `/asset/${v.id}`
                  // }
                  assets
                  assetsGroup={v.sku?.includes("GROUPED-") ? true : false}
                />
              </SwiperSlide>
            )
          })}
      </Swiper>
    </div>
  )
}

export default RelatedProduct
