/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import ImageCardSmall from "../Elements/ImageCardSmall"
import Icon from "../../assets/icons/close-alt.svg"
import IconClose from "../Elements/IconClose"
import CartContext from "../../context/Cart/CartContext"
import MaxQtyMeta from "../Cart/MaxQtyMeta"
import { Link } from "gatsby"
import NotificationContext from "../../context/Notification/NotificationContext"
import { useMediaQuery } from "react-responsive"
function CartItemCard(props) {
  let {
    address,
    asset_title,
    quantity,
    className,
    small,
    // store_name,
    address_name,
    mainIndex,
    storeIndex,
    asset_image,
    storeId,
    assetId,
    price,
    status,
    setAssetQty,
    length,
    assetLength,
  } = props
  const { handleRemoveClick, quantityUpdate } = useContext(CartContext)
  const { toggleNotification } = useContext(NotificationContext)
  const [count, setCount] = useState(parseInt(quantity))
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  })
  const { cart } = useContext(CartContext)
  const increment = () => {
    increaseQty(count + 1)
  }
  const decrement = () => {
    let val = count - 1
    if (val < 0) return null
    setCount(val)
    quantityUpdate(assetId, storeId, val)
  }

  const increaseQty = e => {
    // count total quantity of this item in cart across all stores
    let total = 0
    console.log(cart, "c")
    cart.forEach(item => {
      if (item.asset_id === assetId) {
        item.addresses.map(address => {
          if (parseInt(address.address_id) === parseInt(storeId)) total += e
          else total += parseInt(address.quantity)
        })
        // total += parseInt(item.quantity)
      }
    })

    console.log(total, "total")

    const val = parseInt(e)

    if (
      val <= status.max_qty &&
      val <= status.available_total &&
      total <= status.available_total
    ) {
      setCount(val)
      quantityUpdate(assetId, storeId, val)
      return
    } else if (
      status.max_qty == null &&
      val <= status.available_total &&
      total <= status.available_total
    ) {
      setCount(val)
      quantityUpdate(assetId, storeId, val)
      return
    }
    return toggleNotification({
      error: true,
      content: `Sorry, we don't have enough in stock.`,
    })
  }

  const {
    ordered_last,
    max_qty,
    max_qty_ordered,
    max_qty_frequency,
    available_total,
  } = status

  useEffect(() => {
    //put the qty and asset_id of each order in setAssetQty array keeping previous values
    setAssetQty(prev => [
      ...prev.filter(item => item.address_id !== storeId),
      {
        asset_id: assetId,
        address_id: storeId,
        quantity: count,
        price: price,
      },
    ])
  }, [count])

  useEffect(() => {
    console.log(length, quantity, assetLength, "length")
    setCount(parseInt(quantity))
  }, [length, assetLength, quantity])
  console.log({ count, assetLength, quantity, asset_title })

  return (
    <li
      className={`border-b border-[#EBEBEB] pb-4 mb-4 min-w-full  ${
        className && className
      }`}
    >
      <div className="flex">
        <div className="mr-4">
          <Link to={`/asset/${assetId}`}>
            <ImageCardSmall
              image={asset_image}
              alt={asset_title}
              size={isTablet ? "60" : "90"}
            />
          </Link>
        </div>

        <div className="w-full">
          <div className={`flex ${isTablet ? "" : "items-center"} gap-2 mb-3`}>
            <div className="">
              <h3 className="text-med-16 pr-2">
                <Link to={`/asset/${assetId}`}>{asset_title}</Link>
              </h3>
              <MaxQtyMeta
                // ordered_last={status.ordered_last}
                status={status}
                itemInCartQty={count}
                address={address}
              />
            </div>
            {
              //  <div
              //   className="ml-auto"
              //   role="none"
              //   onClick={() => handleRemoveClick(assetId, storeId)}
              // >
              //   <IconClose small height={2} width={2} callback={() => {}} />
              // </div>
            }
            <div className="flex flex-col gap-2 items-center ml-auto">
              <div
                className={`custom-number-input ${
                  small ? `h-[32px] w-[62px] px-[4px]` : `h-[50px] w-[30%]`
                }  flex bg-white flex-row items-center overflow-hidden`}
                css={css`
                  background: #ffffff;
                  border: 1px solid #ebebeb;
                  box-sizing: border-box;
                  border-radius: 8px;
                  &:hover {
                    border-color: #222;
                  }
                  min-width: ${small ? `62px` : `none`};
                `}
              >
                <button
                  data-action="decrement"
                  onClick={decrement}
                  className={`h-full ${
                    small ? `w-[20px] relative -top-[1px]` : `w-18`
                  } cursor-pointer outline-none bg-white overflow-hidden ${
                    small && `text-reg-12`
                  }`}
                  css={css`
                    border-radius: 8px 0 0 8px;
                  `}
                >
                  <span className="">−</span>
                </button>
                <input
                  type="text"
                  className={` ${
                    small ? `text-reg-12` : `text-med-14`
                  } outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden`}
                  name="custom-input-number"
                  onChange={e => {

                    increaseQty(parseInt(e.target.value))

                    // if (parseInt(max_qty) - parseInt(max_qty_ordered) <= 0)
                    //   return null
                    // let val = parseInt(e.target.value)
                    // if (val <= parseInt(max_qty)) setCount(parseInt(val))
                    // else if (max_qty == null && val < parseInt(available_total))
                    //   setCount(parseInt(val))
                  }}
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  value={count}
                />
                <button
                  // data-action="increment"
                  onClick={increment}
                  className={`h-full ${
                    small ? `w-[20px] relative -top-[1px]` : `w-18`
                  } cursor-pointer bg-white overflow-hidden ${
                    small && `text-reg-12`
                  }`}
                  css={css`
                    border-radius: 0 8px 8px 0;
                  `}
                  disabled={
                    count === parseInt(max_qty) ||
                    parseInt(max_qty) - parseInt(max_qty_ordered) <= 0
                  }
                >
                  <span className="">+</span>
                </button>
              </div>
              <p
                className={`${small ? `` : `w-[60%]`}`}
                css={css`
                  text-transform: uppercase;
                  font-weight: 500;
                  font-size: 13px;
                  letter-spacing: 0.02rem;
                  color: #717171;
                `}
              >
                <h3
                  css={css`
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 13px;
                    letter-spacing: 0.02rem;
                    color: #000;
                  `}
                >
                  {price !== null && parseInt(price) === 0
                    ? "Free"
                    : `$${price}`}
                </h3>
              </p>
            </div>
            <div
              className="mb-8 ml-1"
              role="none"
              onClick={() => handleRemoveClick(assetId, storeId)}
            >
              <IconClose small height={2} width={2} callback={() => {}} />
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default CartItemCard
