/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect } from "react"
import { useState } from "react"
import { Disclosure, RadioGroup, Tab } from "@headlessui/react"
import { StarIcon } from "@heroicons/react/solid"
import DeliveryIcon from "../assets/icons/delivery.svg"
import {
  getCart,
  getSingleAsset,
  getSingleProduct,
  notificationEmail,
  removeAllItemsFromCart,
  sessToken,
} from "../helpers/DrupalHelper"
import { HeartIcon, MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline"
import Layout from "../components/Layout/Pages"
import { Link, navigate } from "gatsby"
import ProductImageBox from "../components/ProductImageBox"
import AssetsCard from "../components/Cards/AssestCard"
import RelatedProduct from "../components/RelatedProduct"
import MetaUpdated from "../components/Elements/MetaUpdated"
import MetaDelivery from "../components/Elements/MetaDelivery"
import Separator from "../components/Elements/Separator"
import CircleAvatar from "../components/Elements/CircleAvatar"
import AddToCartBox from "../components/Elements/AddToCartBox"
import Tag from "../components/Elements/Tag"
import Tags from "../components/Elements/Tags"
import Button from "../components/Elements/Button"
import CartCard from "../components/Cards/CartCard"
import CartContext from "../context/Cart/CartContext"
import BannerSimple from "../components/Commons/BannerSimple"
import Container from "../components/Container"
import SectionCard from "../components/Cards/Dashboard/SectionCard"
import FloatingTextarea from "../components/Elements/Form/FloatingTextarea"
import FloatingCheckbox from "../components/Elements/Form/FloatingCheckbox"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
import { useMediaQuery } from "react-responsive"
import ShippingOptions from "../components/Cart/ShippingOptions"
import CartOrders from "../components/Cart/CartOrders"
import StateContext from "../context/state/StateContext"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const CartPage = () => {
  const [giftOrder, setGiftOrder] = useState(false)
  const [orderNote, setOrderNote] = useState(null)
  const [specifyGiftNotificationEmail, setSpecifyGiftNotificationEmail] =
    useState({})
  const [parentShippingState, setParentShippingState] = useState("standard")
  const [orderState, setOrderState] = useState([
    {
      id: "rushOrder",
      value: "rushOrder",
      parentID: null,
      allChecked: false,
    },
    {
      id: "giftOrder",
      value: "giftOrder",
      parentID: null,
      allChecked: false,
    },
  ])
  const [parentId, setParentId] = useState([])
  const [itemShippingState, setItemShippingState] = useState([])
  const [orderNotes, setOrderNotes] = useState([])
  const [giftEmail, setGiftEmail] = useState([])
  const [assetQty, setAssetQty] = useState([])
  const [notifyEmails, setNotifyEmails] = useState([])
  const uniqueParentId = [...new Set(parentId)]
  const [totalCost, setTotalCost] = useState(0)
  const [estmiateload, setEstimateLoad] = useState(false)

  console.log(assetQty)
  // const [orderStateAll, setOrderStateAll] = useState(false)

  const {
    cart,
    userAddresses,
    handleRemoveClick,
    handleProcessCart,
    setLoading,
    setCartItems,
  } = useContext(CartContext)

  const { state } = useContext(StateContext)
  const { token } = state

  const structuredCart = cart.reduce((acc, curr) => {
    curr.addresses.forEach(address => {
      const newCurr = {
        ...curr,
        addresses: [address],
      }
      const found = acc.find(
        item => item.addresses.address_id === address.address_id
      )
      if (found) {
        found.items.push(newCurr)
      } else {
        acc.push({
          addresses: address,
          items: [newCurr],
          cost: newCurr.cost,
        })
      }
    })
    return acc
  }, [])
  console.log(structuredCart, "structuredCart")
  const { toggleNotification } = useContext(NotificationContext)

  const { userType } = useContext(AuthenticationContext)

  const CartWithPrice = cart.filter((cuval, index) => {
    return cuval.price > 0
  })
  const CartWithoutPrice = cart.filter((cuval, index) => {
    return cuval.price <= 0
  })
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  })

  const processPaidOrderClick = async () => {
    if (
      giftOrder === true &&
      Object.keys(specifyGiftNotificationEmail).length &&
      Object.keys(specifyGiftNotificationEmail).filter(
        f => specifyGiftNotificationEmail[f] === ""
      ).length
    ) {
      return toggleNotification({
        error: true,
        content: "Please provide a notification email address for each store.",
      })
    } else {
      let cartObj = structuredCart.map((item, index) => {
        const rushOrder = uniqueParentId.includes(`rushOrder-0-${index}`)
        const giftOrder = uniqueParentId.includes(`giftOrder-1-${index}`)
        let notify
        // if (
        //   (Object.keys(specifyGiftNotificationEmail).includes(
        //     item.addresses.address_id
        //   ) &&
        //     giftEmail[index]?.email === undefined) ||
        //   giftEmail[index]?.email === ""
        // ) {
        //   return toggleNotification({
        //     error: true,
        //     content:
        //       "Please provide a notification email address for each store.",
        //   })
        // }

        if (giftOrder && giftEmail[index]) {
          notify = giftEmail[index].email
        }
        if (giftOrder && !giftEmail[index]?.email) {
          notify = notifyEmails.find(
            f => f.id === item.addresses.address_id
          )?.res
        }
        console.log(giftEmail)
        const { quantity, ...addressesWithoutQuantity } = item.addresses

        return {
          ...addressesWithoutQuantity,
          shipping: itemShippingState[index]?.shippingState,
          orderNote: orderNotes[index],
          rushOrder,
          giftOrder,
          notify,
        }
      })
      localStorage.setItem("cart_obj", JSON.stringify(cartObj))
      navigate("/CheckOut")
    }
  }

  const processOrderClick = async () => {
    if (
      giftOrder === true &&
      Object.keys(specifyGiftNotificationEmail).length &&
      Object.keys(specifyGiftNotificationEmail).filter(
        f => specifyGiftNotificationEmail[f] === ""
      ).length
    ) {
      return toggleNotification({
        error: true,
        content: "Please provide a notification email address for each store.",
      })
    } else {
      let cartObj = structuredCart.map((item, index) => {
        const rushOrder = uniqueParentId.includes(`rushOrder-0-${index}`)
        const giftOrder = uniqueParentId.includes(`giftOrder-1-${index}`)
        let notify
        // if (
        //   (Object.keys(specifyGiftNotificationEmail).includes(
        //     item.addresses.address_id
        //   ) &&
        //     giftEmail[index]?.email === undefined) ||
        //   giftEmail[index]?.email === ""
        // ) {
        //   return toggleNotification({
        //     error: true,
        //     content:
        //       "Please provide a notification email address for each store.",
        //   })
        // }

        if (giftOrder && giftEmail[index]) {
          notify = giftEmail[index].email
        }
        console.log(giftEmail)
        const { quantity, ...addressesWithoutQuantity } = item.addresses

        return {
          ...addressesWithoutQuantity,
          shipping: itemShippingState[index]?.shippingState,
          orderNote: orderNotes[index],
          rushOrder,
          giftOrder,
          notify,
        }
      })
      if (userType.brandEditor === false) {
        //rmove rush order and gift order from cartObj
        cartObj = cartObj.map(item => {
          const { rushOrder, giftOrder, notify, shipping, ...rest } = item
          return rest
        })
      }
      setLoading(true)

      let obj = {}

      if (userType.brandEditor) {
        obj = {
          giftOrder: giftOrder,
          orderNote: orderNote,
        }

        if (Object.keys(specifyGiftNotificationEmail).length)
          obj.notify = specifyGiftNotificationEmail
      }

      await handleProcessCart(cartObj)

      setLoading(false)
    }
    const temp_cart = cart.map(asset => {
      const stores = asset.status.stores.map(store => {
        const matchingAddress = asset.addresses.find(
          address => address.address_id === store.id
        )
        const maxQtyOrdered = matchingAddress
          ? parseInt(matchingAddress.quantity) + store.max_qty_ordered
          : store.max_qty_ordered
        return { ...store, max_qty_ordered: maxQtyOrdered }
      })
      return {
        ...asset,
        status: { ...asset.status, stores },
        timeStamp: Date.now(),
      }
    })
    localStorage.setItem("temp_cart", JSON.stringify(temp_cart))
  }

  console.log(parentId)
  useEffect(() => {
    //if parentId includes any gift order
    if (uniqueParentId.some(f => f.includes("giftOrder")) === true) {
      let addressIds = new Set()
      cart.filter((f, k) => {
        const { addresses } = f
        addresses.map((address, s) => {
          const { address_id } = address
          addressIds.add(address_id)
        })
      })

      addressIds = Array.from(addressIds)

      if (userAddresses.length === 0) return

      const mapUsersAddresses = addressIds.map(i =>
        userAddresses.find(f => parseInt(f.id) === parseInt(i))
      )

      let requiringManualEmail = {}
      console.log(mapUsersAddresses)

      mapUsersAddresses?.map((item, k) => {
        if (item.type === "store") {
          // ... fetch and check ...
        } else {
          requiringManualEmail[item.id] = ""
        }
      })

      setSpecifyGiftNotificationEmail(requiringManualEmail)
      // const verifyStoreEmails = await verifyStore
    }
  }, [parentId.length])
  useEffect(() => {
    console.log(parentId)
    let notifyAddresses = []
    structuredCart.map((item, index) => {
      const giftOrder = userType.brandEditor
        ? uniqueParentId.includes(`giftOrder-1-${index}`)
        : uniqueParentId.includes(`giftOrder-0-${index}`)
      console.log({ giftOrder })
      const { quantity, ...addressesWithoutQuantity } = item.addresses
      if (giftOrder === true) {
        notifyAddresses.push(addressesWithoutQuantity)
      }
    })
    console.log({ notifyAddresses })
    //make a call to backend
    //make a request to backend to notificationEmail func which will return the email, keep store of all the emails

    // notifyAddresses.map(async (item, index) => {
    //   const res = await notificationEmail(token, item.address_id)
    //   console.log(res, "res")
    //   setNotifyEmails(prevState => [...prevState, res])
    // })
    // console.log(notifyEmails, "notifyEmails")
    // fake response
    // const res = [
    //   {
    //     id: "56599",
    //     email: "test@gmail",
    //   },
    //   {
    //     id: "56600",
    //     email: "test1@gmail",
    //   },
    //   {
    //     id: "56586",
    //     email: "test2@gmail",
    //   },
    // ]
    // setNotifyEmails(res)
  }, [parentId.length])

  useEffect(() => {
    structuredCart.map((item, index) => {
      notificationEmail(token, item.addresses.address_id).then(res => {
        //also put the address id in the setNotifyEmails object with the email
        setNotifyEmails(prevState => [
          ...prevState,
          { res, id: item.addresses.address_id },
        ])
      })
    })
  }, [structuredCart.length])
  //

  useEffect(() => {
    const calculateTotalPrice = items => {
      let total = 0
      items.map(i => {
        const qty = i.addresses.quantity
        const ind_cost = i.cost * qty
        total += ind_cost
        // Iterate over each outer item's 'items' array
      })
      return total
    }

    setTotalCost(calculateTotalPrice(structuredCart))

    // return () => clearTimeout(timer)
  }, [structuredCart])
  console.log(estmiateload, "totalCost")

  return (
    <Layout metaTitle={"Your Cart"}>
      <BannerSimple heading={"Your Cart"} />
      <Container mobileMaxWidth={isTablet ? true : false}>
        {!isTablet ? (
          cart.length === 0 ? (
            <div className="min-h-[200px]">
              <p className="text-center">No Items in Your Cart</p>
            </div>
          ) : (
            <div className="flex">
              <div className="w-full lg:w-[75%] lg:pr-[30px]">
                {
                  //   <SectionCard title="Your assets">
                  //   {cart.length > 0 && (
                  //     <>
                  //       <ul>
                  //         {cart.map((v, k) =>
                  //           v.addresses.map((c, kk) => {
                  //             console.log(v, "v")
                  //             console.log(c, "c")
                  //             return (
                  //               <CartCard
                  //                 small
                  //                 key={`${k}_${kk}`}
                  //                 {...v}
                  //                 {...c}
                  //                 address={c}
                  //                 mainIndex={k}
                  //                 storeIndex={kk}
                  //                 storeId={c.address_id}
                  //                 assetId={v.asset_id}
                  //               />
                  //             )
                  //           })
                  //         )}
                  //       </ul>
                  //       <div className="flex items-center mb-[20px]">
                  //         <span className="ml-auto text-med-14">
                  //           Direct to your Location
                  //         </span>
                  //       </div>
                  //     </>
                  //   )}
                  //   {cart.length === 0 && <p>No Items in Your Cart</p>}
                  // </SectionCard>
                }
                {structuredCart?.map((item, index) => {
                  //find the notify email matching the address
                  const notifyEmail = notifyEmails.find(
                    f => f.id === item.addresses.address_id
                  )
                  console.log(notifyEmail, "notifyEmail")
                  return (
                    <CartOrders
                      length={structuredCart.length}
                      cart={cart}
                      item={item}
                      index={index}
                      key={index}
                      parentShippingState={parentShippingState}
                      setOrderState={setOrderState}
                      orderState={orderState}
                      parentId={parentId}
                      setParentId={setParentId}
                      setItemShippingState={setItemShippingState}
                      setOrderNotes={setOrderNotes}
                      // setSpecifyGiftNotificationEmail={
                      //   setSpecifyGiftNotificationEmail
                      // }
                      // specifyGiftNotificationEmail={
                      //   specifyGiftNotificationEmail
                      // }
                      setGiftEmail={setGiftEmail}
                      setAssetQty={setAssetQty}
                      notifyEmail={notifyEmail}
                    />
                  )
                })}
                <br />
                <br />
                <p
                  className="text-reg-17 cursor-pointer hover:underline mt-[-10px]"
                  onClick={async () => {
                    const remove = await removeAllItemsFromCart(token, {})
                    if (remove === "success") {
                      setCartItems([])
                    }
                  }}
                >
                  Remove all items?
                </p>
              </div>

              <div className="w-full lg:w-[25%]">
                <>
                  {userType.brandEditor === true && (
                    <div>
                      <h1
                        className={
                          isMobile ? `text-strong-15` : `text-strong-25`
                        }
                      >
                        {"Estimated Costs"}
                      </h1>
                      <p className="mb-4 text-reg-17">
                        By processing these orders you agree to the cost
                        estimates shown below. Please note, these are only
                        estimates and actual costs may vary.
                      </p>
                      <p className="mb-4 text-reg-17">
                        <strong>Items :</strong> ${totalCost.toFixed(2)}
                      </p>
                    </div>
                  )}
                  <div>
                    {userType.brandEditor === true && (
                      <>
                        {" "}
                        <h1
                          className={
                            isMobile
                              ? `text-strong-15`
                              : `text-strong-25 !leading-8 py-4`
                          }
                        >
                          Override Shipping Options
                        </h1>
                        <p className="mb-4 text-reg-17">
                          Selections below will be applied to ALL orders in this
                          cart.
                        </p>
                      </>
                    )}
                    <ShippingOptions
                      override
                      extraAll={true}
                      name="parent_shipping"
                      setParentShippingState={setParentShippingState}
                      setOrderState={setOrderState}
                      orderState={orderState}
                      parentId={parentId}
                      setParentId={setParentId}
                      structuredCart={structuredCart.length}
                    />
                  </div>
                </>

                <div>
                  <h1
                    className={isMobile ? `text-strong-15` : `text-strong-25`}
                  >
                    {"Checkout"}
                  </h1>
                  {
                    // (userType.brandManager === true ||
                    // userType.brandEditor === true) &&
                    // userAddresses.length > 0 && (
                    //   <>
                    //     <FloatingCheckbox
                    //       value={giftOrder}
                    //       label="Gift Order"
                    //       description="This order is a gift, please notify the store that it's on its way!"
                    //       name="gift_order"
                    //       hookForm={false}
                    //       onChange={() => setGiftOrder(!giftOrder)}
                    //     />
                    //     {giftOrder === true &&
                    //       Object.keys(specifyGiftNotificationEmail).length >
                    //         0 && (
                    //         <div className="rounded-[8px] bg-[#FFF7E2] px-5 py-5">
                    //           <p className="text-reg-14 !text-[#a57c0b] !leading-[1.5] mb-3">
                    //             There are no user accounts associated with the
                    //             following addresses. Please enter an email address
                    //             where you would like this notification to be sent.
                    //           </p>
                    //           {Object.keys(specifyGiftNotificationEmail).map(
                    //             (item, k) => {
                    //               const addressItem = userAddresses.find(
                    //                 f => parseInt(f.id) === parseInt(item)
                    //               )
                    //               return (
                    //                 <>
                    //                   <div>
                    //                     <p className="text-reg-14 !text-[#a57c0b] uppercase mb-4 max-w-[200px] !leading-[1.5] underline">
                    //                       {addressItem.location}
                    //                     </p>
                    //                   </div>
                    //                   <div
                    //                     // className="mt-"
                    //                     css={css`
                    //                       &&& {
                    //                         label {
                    //                           background-color: #fff7e2 !important;
                    //                           top: ${specifyGiftNotificationEmail[
                    //                             item
                    //                           ] === ""
                    //                             ? `8px`
                    //                             : `14px`};
                    //                           font-size: 13px;
                    //                           color: #a57c0b;
                    //                         }
                    //                         input {
                    //                           border-color: #a57c0b;
                    //                           font-size: 13px;
                    //                           padding-top: 10px !important;
                    //                           padding-bottom: 10px !important;
                    //                           line-height: 23px;
                    //                           &:focus {
                    //                             border-color: #a57c0b !important;
                    //                             ~ label {
                    //                               top: 14px;
                    //                             }
                    //                           }
                    //                         }
                    //                       }
                    //                     `}
                    //                   >
                    //                     <FloatingInput
                    //                       label={"Email address"}
                    //                       name={`address_${item}`}
                    //                       type={"email"}
                    //                       required={true}
                    //                       onChange={e =>
                    //                         setSpecifyGiftNotificationEmail(
                    //                           prevState => ({
                    //                             ...prevState,
                    //                             [item]: e.target.value,
                    //                           })
                    //                         )
                    //                       }
                    //                     />
                    //                   </div>
                    //                 </>
                    //               )
                    //             }
                    //           )}
                    //         </div>
                    //       )}
                    //     <div
                    //       css={css`
                    //         margin: 0em 0 2em;
                    //         border-bottom: 1px solid #ebebeb;
                    //         height: 1px;
                    //       `}
                    //     />
                    //     <FloatingTextarea
                    //       name="note"
                    //       label="Order Note (Optional)"
                    //       description={
                    //         "Include any notes here that you would either like our warehouse staff to see or any store staff to see if you are gifting this order."
                    //       }
                    //       onChange={e => setOrderNote(e.target.value)}
                    //     />
                    //   </>
                    // )
                  }
                  <p className="mb-4 text-reg-17">
                    Items will be delivered to your chosen address(es) in the
                    quantities shown beside.
                  </p>
                  {CartWithPrice && CartWithPrice.length ? (
                    <div>
                      <div className="pb-4">
                        <Button
                          red
                          full
                          onClick={() => processPaidOrderClick()}
                        >
                          Checkout
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="pb-4">
                        <Button red full onClick={() => processOrderClick()}>
                          Process Orders
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        ) : cart.length === 0 ? (
          <div className="min-h-[200px]">
            <p className="text-center">No Items in Your Cart</p>
          </div>
        ) : (
          <div className="flex flex-col">
            <div>
              <div className="flex gap-6 items-center max-[600px]:flex-row max-[600px]:gap-3">
                <h1
                  className={isMobile ? `text-strong-15` : `text-strong-25`}
                  css={css`
                    font-size: 1.5rem;
                    font-weight: 700;
                    font-family: "kansasnew";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 65px;
                    letter-spacing: 0.05em;
                    color: #222222;
                  `}
                >
                  Checkout
                </h1>
                {CartWithPrice && CartWithPrice.length ? (
                  <div className="flex w-full justify-end gap-6">
                    <Button red full onClick={() => processPaidOrderClick()}>
                      Checkout
                    </Button>
                  </div>
                ) : (
                  <Button red full onClick={() => processOrderClick()}>
                    Process Orders
                  </Button>
                )}
              </div>
              <div className="w-full lg:w-[25%]">
                {userType.brandEditor === true && (
                  <>
                    <div>
                      <h1
                        className={
                          isMobile ? `text-strong-15` : `text-strong-25`
                        }
                        css={css`
                          font-size: 1.5rem;
                          font-weight: 700;
                          font-family: "kansasnew";
                          font-style: normal;
                          font-weight: 700;
                          font-size: 25px;
                          line-height: 65px;
                          letter-spacing: 0.05em;
                          color: #222222;
                        `}
                      >
                        Estimated Costs
                      </h1>
                      <p className="mb-4 text-reg-17">
                        By processing these orders you agree to the cost
                        estimates shown below. Please note, these are only
                        estimates and actual costs may vary.
                      </p>
                      <p className="mb-4 text-reg-17">
                        <strong>Items :</strong> ${totalCost.toFixed(2)}
                      </p>
                    </div>
                    <div>
                      <h1
                        className={
                          isMobile
                            ? `text-strong-15`
                            : `text-strong-25 !leading-8 py-4`
                        }
                        css={css`
                          font-size: 1.5rem;
                          font-weight: 700;
                          font-family: "kansasnew";
                          font-style: normal;
                          font-weight: 700;
                          font-size: 25px;
                          line-height: 40px;
                          letter-spacing: 0.05em;
                          color: #222222;
                        `}
                      >
                        Override Shipping Options
                      </h1>
                      <p className="mb-4 text-reg-17">
                        Selections below will be applied to ALL orders in this
                        cart.
                      </p>
                      <ShippingOptions
                        override
                        extraAll={true}
                        name="parent_shipping"
                        setParentShippingState={setParentShippingState}
                        setOrderState={setOrderState}
                        orderState={orderState}
                        parentId={parentId}
                        setParentId={setParentId}
                        structuredCart={structuredCart.length}
                      />
                    </div>
                  </>
                )}
              </div>
              {
                // (userType.brandManager === true ||
                // userType.brandEditor === true) &&
                // userAddresses.length > 0 && (
                //   <>
                //     <FloatingCheckbox
                //       value={giftOrder}
                //       label="Gift Order"
                //       description="This order is a gift, please notify the store that it's on its way!"
                //       name="gift_order"
                //       hookForm={false}
                //       onChange={() => setGiftOrder(!giftOrder)}
                //     />
                //     {giftOrder === true &&
                //       Object.keys(specifyGiftNotificationEmail).length >
                //         0 && (
                //         <div className="rounded-[8px] bg-[#FFF7E2] px-5 py-5">
                //           <p className="text-reg-14 !text-[#a57c0b] !leading-[1.5] mb-3">
                //             There are no user accounts associated with the
                //             following addresses. Please enter an email address
                //             where you would like this notification to be sent.
                //           </p>
                //           {Object.keys(specifyGiftNotificationEmail).map(
                //             (item, k) => {
                //               const addressItem = userAddresses.find(
                //                 f => parseInt(f.id) === parseInt(item)
                //               )
                //               return (
                //                 <>
                //                   <div>
                //                     <p className="text-reg-14 !text-[#a57c0b] uppercase mb-4 max-w-[200px] !leading-[1.5] underline">
                //                       {addressItem.location}
                //                     </p>
                //                   </div>
                //                   <div
                //                     // className="mt-"
                //                     css={css`
                //                       &&& {
                //                         label {
                //                           background-color: #fff7e2 !important;
                //                           top: ${specifyGiftNotificationEmail[
                //                             item
                //                           ] === ""
                //                             ? `8px`
                //                             : `14px`};
                //                           font-size: 13px;
                //                           color: #a57c0b;
                //                         }
                //                         input {
                //                           border-color: #a57c0b;
                //                           font-size: 13px;
                //                           padding-top: 10px !important;
                //                           padding-bottom: 10px !important;
                //                           line-height: 23px;
                //                           &:focus {
                //                             border-color: #a57c0b !important;
                //                             ~ label {
                //                               top: 14px;
                //                             }
                //                           }
                //                         }
                //                       }
                //                     `}
                //                   >
                //                     <FloatingInput
                //                       label={"Email address"}
                //                       name={`address_${item}`}
                //                       type={"email"}
                //                       required={true}
                //                       onChange={e =>
                //                         setSpecifyGiftNotificationEmail(
                //                           prevState => ({
                //                             ...prevState,
                //                             [item]: e.target.value,
                //                           })
                //                         )
                //                       }
                //                     />
                //                   </div>
                //                 </>
                //               )
                //             }
                //           )}
                //         </div>
                //       )}
                //     <div
                //       css={css`
                //         margin: 0em 0 2em;
                //         border-bottom: 1px solid #ebebeb;
                //         height: 1px;
                //       `}
                //     />
                //     <FloatingTextarea
                //       name="note"
                //       label="Order Note (Optional)"
                //       description={
                //         "Include any notes here that you would either like our warehouse staff to see or any store staff to see if you are gifting this order."
                //       }
                //       onChange={e => setOrderNote(e.target.value)}
                //     />
                //   </>
                // )
              }
              <p className="mb-4 text-reg-17 max-[600px]:mt-4">
                Items will be delivered to your chosen address(es) in the
                quantities shown beside.
              </p>
            </div>
            <div className="w-full lg:pr-[30px]">
              {
                //   <SectionCard title="Your assets">
                //   {cart.length > 0 && (
                //     <>
                //       <ul>
                //         {cart.map((v, k) =>
                //           v.addresses.map((c, kk) => {
                //             console.log(v, "v")
                //             console.log(c, "c")
                //             return (
                //               <CartCard
                //                 small
                //                 key={`${k}_${kk}`}
                //                 {...v}
                //                 {...c}
                //                 address={c}
                //                 mainIndex={k}
                //                 storeIndex={kk}
                //                 storeId={c.address_id}
                //                 assetId={v.asset_id}
                //               />
                //             )
                //           })
                //         )}
                //       </ul>
                //       <div className="flex items-center mb-[20px]">
                //         <span className="ml-auto text-med-14">
                //           Direct to your Location
                //         </span>
                //       </div>
                //     </>
                //   )}
                //   {cart.length === 0 && <p>No Items in Your Cart</p>}
                // </SectionCard>
              }

              {structuredCart?.map((item, index) => {
                //find the notify email matching the address
                const notifyEmail = notifyEmails.find(
                  f => f.id === item.addresses.address_id
                )
                return (
                  <CartOrders
                    cart={cart}
                    item={item}
                    index={index}
                    key={index}
                    parentShippingState={parentShippingState}
                    setOrderState={setOrderState}
                    orderState={orderState}
                    parentId={parentId}
                    setParentId={setParentId}
                    setItemShippingState={setItemShippingState}
                    setOrderNotes={setOrderNotes}
                    // setSpecifyGiftNotificationEmail={
                    //   setSpecifyGiftNotificationEmail
                    // }
                    // specifyGiftNotificationEmail={specifyGiftNotificationEmail}
                    setGiftEmail={setGiftEmail}
                    setAssetQty={setAssetQty}
                    notifyEmail={notifyEmail}
                  />
                )
              })}

              <br />
              <br />
              <p
                className="text-reg-17 cursor-pointer hover:underline mt-[-10px]"
                onClick={async () => {
                  const remove = await removeAllItemsFromCart(token, {})
                  if (remove === "success") {
                    setCartItems([])
                  }
                }}
              >
                Remove all items?
              </p>
            </div>
          </div>
        )}

        <div className="">
          <div className="mt-10">
            <RelatedProduct />
          </div>
        </div>
      </Container>
    </Layout>
  )
}
// export async function getServerData(context) {
//   // console.log(context)
//   // let id = 1

//   try {
//     const token = await sessToken()
//     const res = await getCart(token)
//     console.log(res)
//     if (!res) {
//       return {
//         props: false,
//       }
//     }
//     return {
//       props: res,
//     }
//   } catch (error) {
//     return {
//       status: 500,
//       headers: {},
//       props: {},
//     }
//   }
// }
export default CartPage
